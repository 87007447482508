<template>
    <div class=" countdown-container">
        <div class="container">

            <div class="row justify-content-center align-items-center" style="min-height: 100vh">
                <div class="col-auto">

                    <div class="row countdown mb-5">
                        <div class="col">
                            <div class="timer" id="day">{{days}}</div>
                            <div class="unit">DAYS</div>
                        </div>
                        <div class="col">
                            <div class="timer" id="hours">{{hours}}</div>
                            <div class="unit">HOURS</div>
                        </div>
                        <div class="col">
                            <div class="timer" id="minutes">{{minutes}}</div>
                            <div class="unit">MINUTES</div>
                        </div>
                        <div class="col">
                            <div class="timer" id="seconds">{{seconds}}</div>
                            <div class="unit">SECONDS</div>
                        </div>
                    </div>

                    <router-link tag="div" to="/register" class="mb-3">
                        <div class="button">REGISTER</div>
                    </router-link>

                    <router-link tag="div" to="/login" class="mb-3">
                        <div class="button">LOGIN</div>
                    </router-link>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        async created() {
            await this.$router.push('/login');
            // let countDownDate = new Date('Jan 15 2021 15:00:00 GMT+0800').getTime();
            //
            // let x = setInterval(function () {
            //     let nowDate = new Date().getTime();
            //     let distance = countDownDate - nowDate;
            //
            //     this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
            //     this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            //     this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            //     this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
            //
            //     this.days = this.days < 10 ? "0" + this.days : this.days;
            //     this.hours = this.hours < 10 ? "0" + this.hours : this.hours;
            //     this.minutes = this.minutes < 10 ? "0" + this.minutes : this.minutes;
            //     this.seconds = this.seconds < 10 ? "0" + this.seconds : this.seconds;
            //
            //     if (distance < 0) {
            //         clearInterval(x);
            //         this.$router.push('/login');
            //     }
            // }.bind(this), 1000);
        },
        data() {
            return {
                days: "00",
                hours: "00",
                minutes: "00",
                seconds: "00"
            }
        }
    }
</script>

<style lang="scss" scoped>
    $primary: #80dcdf;

    .countdown-container {
        color: white;
        background-image: url("../assets/image/background_space.png");
        background-position: center center;
        background-size: cover;
    }

    .timer {
        font-size: 68px;
        width: 80px;
        text-align: center;
        color: $primary;
    }

    .unit {
        text-align: center;
    }

    .button {
        font-size: 22px;
        color: $primary;
        border: 2px solid $primary;
        border-radius: 50px;
        text-align: center;
        cursor: pointer;
    }


    @media (max-width: 768px) {
        .timer {
            font-size: 48px;
            width: auto;
        }
    }
</style>
